




































































































































#profile-tabs {
    .vs-tabs--content {
        padding: 0;
    }
}
